export default {
  startsWith: "Commence par",
  contains: "Contient",
  notContains: "Ne contient pas",
  endsWith: "Termine par",
  equals: "Est",
  notEquals: "N'est pas",
  noFilter: "Aucun filtre",
  lt: "En dessous de",
  lte: "En dessous de ou égal à",
  gt: "Au dessus de",
  gte: "Au dessus de ou égal à",
  dateIs: "Date est",
  dateIsNot: "Date n'est pas",
  dateBefore: "Date est avant",
  dateAfter: "Date est après",
  clear: "Effacer",
  apply: "Appliquer",
  matchAll: "Match All",
  matchAny: "Match Any",
  addRule: "Ajouter règle",
  removeRule: "Supprimer règle",
  accept: "Oui",
  reject: "Non",
  choose: "Choisir",
  upload: "Charger",
  cancel: "Anuler",
  dayNames: [
    "Dimanche",
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
  ],
  dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
  dayNamesMin: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"],
  monthNames: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Decembre",
  ],
  monthNamesShort: [
    "Jan",
    "Fev",
    "Mar",
    "Avr",
    "Mai",
    "Juin",
    "Jui",
    "Août",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  today: "Aujourd'hui",
  weekHeader: "Sem",
  firstDayOfWeek: 0,
  dateFormat: "dd/mm/yy",
  weak: "Faible",
  medium: "Convenable",
  strong: "Fort",
  passwordPrompt: "Entrez un mot de passe",
  emptyFilterMessage: "Aucun résultat",
  emptyMessage: "Aucune option",
};
