import { createRouter, createWebHistory } from "vue-router";
import { projectAuth } from "@/firebase/config";

const requireAuth = (to, from, next) => {
  const user = projectAuth.currentUser;
  if (to.name !== "Login" && !user && !to.meta.anonymous) {
    next({ name: "Login" });
  } else next();
};

const routes = [
  {
    path: "/auth",
    redirect: { name: "Login" },
    component: () => import("../layouts/Auth"),
    meta: { anonymous: true },
    children: [
      {
        path: "login",
        name: "Login",
        component: () => import("../views/auth/Login"),
      },
      {
        path: "forgotten_password",
        name: "ForgottenPassword",
        component: () => import("../views/auth/_ForgottenPassword"),
      },
      {
        path: "register",
        name: "Register",
        component: () => import("../views/auth/Register"),
      },
    ],
  },
  {
    path: "/",
    redirect: { name: "Homepage" },
    component: () => import("../layouts/Default"),
    // beforeEnter: requireAdmin,
    // meta: { anonymous: true },
    children: [
      {
        path: "dashboard",
        name: "Homepage",
        component: () => import("../views/dashboard/Homepage"),
      },
      {
        path: "boards",
        name: "BoardsList",
        component: () => import("../views/boards/List"),
      },
      {
        path: "boards-new",
        name: "BoardsNew",
        component: () => import("../views/boards/New"),
      },
      {
        path: "mercurial",
        name: "Mercurial",
        component: () => import("../views/mercurial/List"),
      },
      {
        path: "easilys-mercurial",
        name: "EasilysMercurial",
        component: () => import("../views/mercurial/EasilysList"),
      },
      {
        path: "rates",
        name: "Rates",
        component: () => import("../views/mercurial/rates/List"),
      },
      {
        path: "centers",
        name: "CentersList",
        component: () => import("../views/centers/List"),
      },
      {
        path: "users",
        name: "UsersList",
        component: () => import("../views/users/List"),
      },
      {
        path: "users/roles",
        name: "RolesList",
        component: () => import("../views/users/roles/List"),
      },
    ],
  },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(requireAuth);

export default router;
